export { terminalSelectorInput };

const terminalSelectorInput = {
  htmlContent: () => {
    return `<input 
            type="search" 
            class="terminal-selector__autocomplete ui-autocomplete-input" 
            placeholder="Search for a location" aria-label="Search for a location">
            <ul tabindex="0" class="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front terminal-list"></ul>`;
  },
  init: (terminalData) => {
    let filteredTerminalData = setupTerminalData(terminalData);
    let inputBox = document.querySelector('.terminal-selector__autocomplete');
    let listItem = document.querySelector('.terminal-list');
    if (inputBox) {
      inputBox.addEventListener('keyup', (e) => {
        searchData(filteredTerminalData, e.target.value);
      });

      inputBox.addEventListener('focusin', () => {
        listItem.style.display = 'block';
      });

      inputBox.addEventListener('focusout', () => {
        setTimeout(() => {
          listItem.style.display = 'none';
        }, 200);
      });
    }
  },
};

const setupTerminalData = (terminalData) => {
  let data = terminalData;
  data = data
    .map((e) => e.ChildItems)
    .flat()
    .map((e) => e.ChildItems)
    .flat();
  return data;
};

const searchData = (terminalData, searchString) => {
  if (searchString) {
    let result = terminalData.filter((ele) => {
      return (
        ele.MenuTitle.toLowerCase().includes(searchString.toLowerCase()) ||
        ele.Synonyms.toString()
          .toLowerCase()
          .includes(searchString.toLowerCase())
      );
    });
    if (result.length === 0) {
      result = [
        {
          MenuTitle: 'No results found',
          PageUrl: '',
        },
      ];
    }
    renderDropdown(result);
  }
};

const renderDropdown = (data) => {
  let listItem = document.querySelector('.terminal-list');
  let htmlContent = '';
  if (listItem) {
    data.forEach((ele) => {
      htmlContent += `<li class="ui-menu-item ">
                        <div tabindex="-1" class="ui-menu-item-wrapper" data-link="${ process.env.APP_BASE_URL + ele.PageUrl }">${ele.MenuTitle}</div>
                      </li>`;
    });
  }
  listItem.innerHTML = htmlContent;
  listItem.style.display = 'block';

  let listElements = document.querySelectorAll('.ui-menu-item');

  if (listElements) {
    listElements.forEach((el) => {
      el.addEventListener('click', (e) => {
        if (e.target.getAttribute('data-link')) {
          document.querySelector('.terminal-selector__autocomplete').value =
            e.target.innerText;
          window.open(`${e.target.getAttribute('data-link')}`, '_self');
          closeMenu();
        }
      });
      el.addEventListener('mouseover', (e) => {
        e.target.style.cursor = 'pointer';
        e.target.classList.add('ui-state-active');
      });
      el.addEventListener('mouseout', (e) => {
        e.target.classList.remove('ui-state-active');
      });
    });
  }
};

const closeMenu = () => {
  let listItem = document.querySelector('.terminal-list');
  listItem.innerHTML = '';
  listItem.style.display = 'none';
};
