export { globalAlert };

const globalAlert = {
  init: () => {
    let closeAlert = document.querySelector('.crisis-alert__close');

    if(closeAlert){
      closeAlert.addEventListener('click', () => {
        window.sessionStorage.setItem('global-alert', 'hide');
        document.querySelector('.crisis-alert').classList.add('modal--hidden');
      });
    }
  },
  htmlContent: (globalAlertContent) => {
    let htmlContent = '';
    if (
      globalAlertContent &&
      globalAlertContent[0] &&
      window.sessionStorage.getItem('global-alert') !== 'hide'
    ) {
      htmlContent = `<div class="modal__bg">
                        <div class="modal__container global-alert--container">
                            <header class="modal__header">
                                <button type="button" class="modal__close crisis-alert__close" aria-label="Close alert">
                                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle stroke="#FF6441" fill="#FFEFEC" cx="16" cy="16" r="15.5"></circle><path d="M19.824 11L21 12.176 17.176 16 21 19.824 19.824 21 16 17.176 12.176 21 11 19.824 14.824 16 11 12.176 12.176 11 16 14.823 19.824 11z" fill="#FF6441"></path></g></svg>
                                </button>
                            </header>
                            <div class="crisis-alert__container-inner equal-height">
                                <div class="crisis-alert__text-container">
                                    <span class="crisis-alert__icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 64" width="72" height="64"><path d="m36.006 4-32 56h64Zm3.473-1.985 32 56C73.003 60.682 71.077 64 68.006 64h-64C.935 64-.991 60.682.533 58.015l32-56c1.536-2.687 5.41-2.687 6.946 0zM34.506 49h3v3h-3zm0-24h3v20h-3z" fill="#e21f2d"></path></svg>
                                    </span>
                                    <p>${globalAlertContent[0].Message}</p>
                                    <br class="t-last-br">
                                </div>
                            </div>
                        </div>
                    </div>`;
    }
    return htmlContent;
  },
};
