import { terminalSubHeader } from './terminal-nav-sub-header';
import { terminalSelector } from '../global-nav/header-terminal-selector';
import { loginAndCreateNavBar } from '../../components/global-nav/header-login-create';
import { terminalToggleButton } from './terminal-nav-toggle';
import { terminalNavMenuItems } from './terminal-nav-menu-items';
import { terminalSwipe } from './terminal-swipe';
import { searchInputNavBar } from '../global-nav/header-input-search';
import { terminalAlert } from './terminal-alert';
import { addCookiePolicy } from '../cookie-policy';
export { startTerminalNav };

const startTerminalNav = async (apiContent) => {
  let globalNavContent = apiContent.header;
  let htmlContent = '';
  htmlContent = `<div class="header__top-bar">
                  ${terminalAlert.htmlContent(apiContent.terminalAlert)}
                </div>
                  <div class="header__middle-bar">
                    <a href="/" class="main__logo" aria-label="Logo">
                      <span class="main__logo-no-tagline">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 498 79.6" width="498" height="79.6"><path d="M89 42.8v-8.3l62.4-28.6v11zM23.1 53.2V59l96.8-48.6V0zM0 79.6l53.4-22.1v-6.7L0 75.2z" fill="#ff6441"></path><path d="M409.1 49.1l-12.9 29.5h7.5l2.8-6.4h14.9l2.8 6.4h7.9l-12.8-29.5zm-.1 17.4l5-11.4 5 11.4zm-95.8 12.1h5.7l7.9-16.6v16.6h7.1V49.1h-8.4l-9.4 21.4-9.4-21.4H298v29.5h7.1V62zm-167 0h5.7l7.9-16.6v16.6h7.1V49.1h-8.4l-9.4 21.4-9.4-21.4H131v29.5h7.1V62zm107.7-29.5v6.1h-20.5v6h17.1v5.9h-17.1v5.5h20.4v6.1h-28V49.2h28.1zm-185.8 0L55.2 78.6h7.5l2.8-6.4h14.9l2.8 6.4h7.9L78.3 49.1zM68 66.5l5-11.4 5 11.4zm49.8-4h-14.4v-7.6h14.4zM95.5 49.1v29.5h7.7v-10h17.3c2.2 0 4-1.3 4.2-4.2v-11c0-2.4-2.1-4.3-4.6-4.3H95.5zm349.3 0h-7.7v29.5h26.5v-6.1h-18.8zm-86 0v29.5h7.6V58.3l16.3 20.3h8.4V49.1h-7.7v20.3l-15.9-20.3zm-16.2 29.5h7.4V49.2h-7.4zM187.9 49.1v6.3h11.9v23.2h7.9V55.4h11.9v-6.3zm285.4 0c-2.2-.1-4.3 1.9-4.3 4v9.6c0 2.5 2.1 4.3 4.2 4.3h17.1v5.7h-13.6v-2.4h-8v4.1c0 2.2 2 4.3 4.3 4.3h20.8c2.1 0 4.2-1.6 4.2-4.4v-9.2c-.1-2.7-2.1-4.3-4.3-4.4h-16.8v-5.5h13.2v1.9h7.5v-3.9c.1-1.9-1.8-4.1-4.3-4h-20zm-212.4 0v29.5h7.5V67.8h8.6l5.8 10.8h8l-6-10.8h.7c2.3-.2 4-1.9 4-4.4v-10c0-2.4-2.1-4.3-4.6-4.3zm21.9 12.8h-14.5v-6.7h14.5z" fill="#3c3c46"></path></svg>
                      </span>
                    </a>
                    <div class="header__group">
                      ${terminalSelector.htmlContent()}
                      <nav class="navigation toggle--link navigation--global">
                        ${terminalToggleButton.htmlContent(globalNavContent)}
                      </nav>
                      ${terminalSwipe.htmlContent()}
                      <div class="header__separator"></div>
                    </div>
                    <nav class="navigation navigation--login">
                      ${loginAndCreateNavBar.htmlContent()}
                    </nav>
                    <button type="button" tabindex="-1" class="menu-icon" aria-hidden="true">
                      <div class="menu-icon__line"></div>
                      <div class="menu-icon__line"></div>
                      <div class="menu-icon__line"></div>
                    </button>
                  </div>
                  ${terminalSubHeader.htmlContent(apiContent.terminalNav, apiContent.terminalLanguages, apiContent.terminalHeaderTitle)}
                </div>`;

  let documentElement = document.createElement('header');
  documentElement.id = 'ign_header';
  documentElement.className = 'header terminal-selector header--large';
  documentElement.innerHTML = htmlContent;
  document.body.insertBefore(documentElement, document.body.firstChild);
  bindEvents(apiContent);
  addCookiePolicy();
};

const bindEvents = (apiContent) => {
  loginAndCreateNavBar.init();
  terminalToggleButton.init();
  terminalSelector.init(apiContent.terminals);
  terminalNavMenuItems.init();
  searchInputNavBar.init();
  terminalSubHeader.init();
  terminalSwipe.init();

  if (apiContent.hasTerminalAlert) {
    terminalAlert.init();
  }
  scrollEvent();
};

let lastScroll = 0;
let threshold = 5;

const scrollEvent = () => {
  document.addEventListener('scroll', () => {
    let newScroll = window.scrollY;
    let terminalSelector = document.querySelector('.terminal-selector');
    terminalSelector.classList.remove('header--alert-open');

    if (newScroll - lastScroll > threshold) {
      const toggeleButton = document.querySelector('.navigation__toggle');
      if (!toggeleButton.className.includes('navigation__toggle--active')) {
        document
          .querySelector('.terminal-selector')
          .classList.add('header--slim');
      }
    } else if (newScroll - lastScroll < -threshold) {
      // "down" code here
      document
        .querySelector('.terminal-selector')
        .classList.remove('header--slim');
    }

    lastScroll = newScroll;
  });
};
