export { terminalAlert };

const terminalAlert = {
  init: () => {
    let terminalSelector = document.querySelector('.terminal-selector');
    if (window.sessionStorage.getItem('terminal-alert') !== 'hide') {
      terminalSelector.classList.add('header--alert');
      if (window.innerWidth > 600) {
        document.querySelector('#main').style.marginTop = '200px';
      } else {
        document.querySelector('#main').style.marginTop = '140px';
      }
    }
    let element = document.querySelector('.terminal-alert__expand');
    if (element) {
      element.addEventListener('click', () => {
        terminalSelector.classList.add('header--alert-open');
      });

      document
        .querySelector('.terminal-alert__close')
        .addEventListener('click', (e) => {
          e.preventDefault();
          terminalSelector.classList.remove('header--alert');
          terminalSelector.classList.remove('header--alert-open');
          document.querySelector('.header__top-bar').style.display = 'none';
          window.sessionStorage.setItem('terminal-alert', 'hide');
          document.querySelector('#main').style.marginTop = '160px';
        });
    }
  },
  htmlContent: (terminalAlert) => {
    let htmlContent = '';
    if (
      terminalAlert &&
      window.sessionStorage.getItem('terminal-alert') !== 'hide'
    ) {
      handleDocumentClick();
      const lang = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : null;
      htmlContent = `<div class="terminal-alert">
                              <div class="message message--red">
                                  <div class="message__icon"><svg class="info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18">
                                      <path class="info__path" xmlns="http://www.w3.org/2000/svg" d="M9 16A7 7 0 109 2a7 7 0 000 14zm0 2A9 9 0 119 0a9 9 0 010 18zM8 7h2v7H8V7zm0-3h2v2H8V4z" fill="#ff6441" fill-rule="evenodd"></path></svg>
                                  </div>
                                  <div class="message__text">
                                      <div class="terminal-alert__message-text">
                                          <div class="terminal-alert__short"><p>${terminalAlert[0].Message}</p></div>
                                          <button type="button" class="terminal-alert__expand a">
                                              ${lang.readMore}...
                                          </button>
                                      </div>
                                  </div>
                                  <button type="button" class="terminal-alert__close message__close" aria-label=${lang.closeAlert}><svg class="close" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path class="close__path" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1z"></path>
                                      <path class="close__path" d="M15.824 7 17 8.176 13.176 12 17 15.824 15.824 17 12 13.176 8.176 17 7 15.824 10.824 12 7 8.176 8.176 7 12 10.823 15.824 7z"></path>
                                      </svg>
                                  </button>
                              </div>
                         </div>`;
    }
    return htmlContent;
  },
};

const handleDocumentClick = () => {
  document.addEventListener('click', (e) => {
    let topBar = document.querySelector('.header__top-bar');
    if (!topBar.contains(e.target)) {
      let terminalSelector = document.querySelector('.terminal-selector');
      terminalSelector.classList.remove('header--alert-open');
    }
  });
};
