const getTerminalRegion = () => {
  const pathname = window.location.pathname;
  /* 
    pathname will get the path's for example
    /en/conakry
    /en/conakry/about-us
    /en/los-angeles
  */

  const locArr = pathname.split('/');
  let url = locArr.slice(0, 3).join('/');
  /*
    locationUrl will split all by slash '/' and slice after the 3rd element and pick 2nd and 3rd element, and join the result with a slash '/'.

    -> /en/conakry/about-us
    -> ['','en','conakry','about-us']
    -> ['','en','conakry']
    -> ['en', 'conakry']
    -> /en/conakry (Result)
    
  */

  //  TODO: pick it from the api content
  let name = locArr[2];
    
  return { url, name };
};

export { getTerminalRegion };
