export { terminalNavMenuItems };

const terminalNavMenuItems = {
  init: () => {
    let menuList = document.querySelectorAll('.sub-nav-link');
    let topMenuList = document.querySelectorAll('.sub-navigation-item');
    let prevSecondaryNav;
    let isMouseInsideParent = false;
    let leaveTimeout;
    if (menuList && window.outerWidth > 1024) {
      menuList.forEach((el) => {
        el.addEventListener('mouseenter', (e) => {
          e.preventDefault();
          isMouseInsideParent = true;
          menuList.forEach((el) => {
            el.classList.remove('navigation__link--active');
            el.nextElementSibling.classList.remove(
              'secondary-navigation--active'
            );
          });
          e.target.classList.add('navigation__link--active');
          e.target.parentElement.lastElementChild.classList.add(
            'secondary-navigation--active'
          );
          prevSecondaryNav = e.target.parentElement.lastElementChild;
        });

        el.addEventListener('mouseleave', (e) => {
          e.preventDefault();
          if (!el.contains(e.relatedTarget)) {
            isMouseInsideParent = false;
          }
        });
      });
    }
    if (topMenuList && window.outerWidth > 1024) {
      topMenuList.forEach((el) => {
        el.addEventListener('mouseleave', () => {
          clearTimeout(leaveTimeout);
          leaveTimeout = setTimeout(function () {
            if (!isMouseInsideParent) {
              if (prevSecondaryNav.classList) {
                prevSecondaryNav.classList.remove(
                  'secondary-navigation--active'
                );
              }
              prevSecondaryNav.previousElementSibling.classList.remove(
                'navigation__link--active'
              );
            }
          }, 500);
        });
      });

      const navLinks = document.querySelectorAll('.low-menu-secondary-nav');
      let secondaryLeave;
      var listItemsArray = Array.from(navLinks);
      for (let i = 0; i < listItemsArray.length; i++) {
        listItemsArray[i].addEventListener('mouseenter', function () {
          isMouseInsideParent = true;
        });
        listItemsArray[i].addEventListener('mouseleave', (e) => {
          clearTimeout(secondaryLeave);
          secondaryLeave = setTimeout(function () {
            if (!listItemsArray[i].contains(e.relatedTarget)) {
              listItemsArray[i].parentElement.classList.remove(
                'navigation__link--active'
              );
              isMouseInsideParent = false;
            }
          }, 500);
        });
      }
    }
    if (window.outerWidth <= 1024) {
      let navList = document.querySelectorAll('.sub-nav-link.navigation__link');
      if (navList) {
        navList.forEach((el, index) => {
          el.addEventListener('click', (e) => {
            e.preventDefault();
            handleNavClick(
              navList,
              el,
              !el.className.includes('navigation__link--active')
            );
            el.setAttribute('tabIndex', index);
          });
        });
      }
    }
    const handleNavClick = (navList, selectedNav, showNav = true) => {
      removeAllActiveClassFromList(navList, selectedNav);
      if (showNav) {
        selectedNav.classList.add('navigation__link--active');
        selectedNav.setAttribute('aria-haspopup', true);
        selectedNav.setAttribute('aria-expanded', true);

        const secondaryNavRef =
          selectedNav.parentElement.children[
            selectedNav.parentElement.children.length - 1
          ];
        secondaryNavRef.classList.add('secondary-navigation--active');
        setTimeout(() => {
          addTranslateVisibleSecondary(secondaryNavRef, 'add');
        }, 300);

        selectedNav.parentNode.classList.add('navigation__item--active');
        addAniMobileFormSecondaryNav(selectedNav, 'add');
      }
    };

    const addAniMobileFormSecondaryNav = (navref, type) => {
      let secondaryNavRef =
        navref.parentElement.children[navref.parentElement.children.length - 1];
      if (type === 'add') {
        secondaryNavRef.style.display = 'block';
        setTimeout(() => {
          secondaryNavRef.querySelector(':first-child').style.opacity = 1;
          secondaryNavRef.querySelector(':first-child').style.transform =
            'translateY(0px)';
          secondaryNavRef.querySelector(
            ':first-child'
          ).style.transitionDuration = '0.3s';
        }, 100);
      } else {
        setTimeout(() => {
          secondaryNavRef.removeAttribute('style');
        }, 300);
        secondaryNavRef.querySelector(':first-child').removeAttribute('style');
      }
    };

    const addTranslateVisibleSecondary = (navref, type) => {
      const secondaryNavItems = navref.querySelectorAll(
        '.secondary-navigation__item'
      );
      secondaryNavItems.forEach((element) => {
        if (type === 'add') {
          element.classList.add('secondary-navigation__item--visible');
        } else {
          element.classList.remove('secondary-navigation__item--visible');
        }
      });
    };

    const removeAllActiveClassFromList = (navList, selectedNav) => {
      navList.forEach((e) => {
        const navRef = selectedNav ? selectedNav : e;
        if (e.className.includes('navigation__link--active')) {
          const secondaryNavRef =
            e.parentElement.children[navRef.parentElement.children.length - 1];
          addTranslateVisibleSecondary(secondaryNavRef, 'remove');
          e.classList.remove('navigation__link--active');
          setTimeout(() => {
            secondaryNavRef.classList.remove('secondary-navigation--active');
          }, 300);
          e.parentNode.classList.remove('navigation__item--active');
          addAniMobileFormSecondaryNav(e, 'remove');
          navRef.setAttribute('aria-expanded', false);
        }
      });
    };
  },
  htmlContent: (menuItems) => {
    return `<nav class="navigation navigation--dropdown navigation--terminal">
               <ul class="navigation__list">
                  ${generateMenuItems(menuItems)}
               </ul>
            </nav>`;
  },
};

const getImg = (data) => {
  if (data.ImgPath) {
    return `<img class="navigation__link-icon" src="${data.ImgPath}" width="22" height="14" alt="">`;
  } else {
    return '';
  }
};

const generateMenuItems = (data) => {
  let htmlContent = '';
  if (data) {
    data.forEach((ele) => {
      if (ele.MenuTitle !== 'Language') {
        let cssClass = ele.ImgPath
          ? 'sub-navigation-item navigation__item navigation__item--highlight'
          : 'sub-navigation-item navigation__item';
        htmlContent += `<li class="${cssClass}">
                           <button type="button" class="sub-nav-link navigation__link" tabindex="0" aria-haspopup="true" aria-expanded="true">
                              ${getImg(ele)}
                              ${ele.MenuTitle}
                              <span class="navigation__arrow-icon"></span>
                           </button>
                           <div class="low-menu-secondary-nav secondary-navigation" aria-hidden="true">
                              <ul class="secondary-navigation__list">
                                 ${generateSubMenuItems(ele.ChildItems)}
                              </ul>
                           </div>
                        </li>`;
      }
    });

    return htmlContent;
  }
};

const generateSubMenuItems = (data) => {
  let htmlContent = '';

  if (data) {
    data.forEach((ele) => {
      htmlContent += `<li class="secondary-navigation__item">
                        <a tabindex="-1" class="secondary-navigation__link" href="${ele.PageUrl}">
                           ${ele.MenuTitle}
                        </a>
                     </li>`;
    });

    return htmlContent;
  }
};
