import { startNavbarGlobal } from '../global-nav/header-nav';
export { terminalToggleButton };

const terminalToggleButton = {
  init: () => {
    startNavbarGlobal.init();

    const toggeleButton = document.querySelector('.navigation__toggle');
    const headerLarge = document.querySelector('.header--large');
    toggeleButton.addEventListener('click', () => {
      if (toggeleButton.className.includes('navigation__toggle--active')) {
        toggeleButton.classList.remove('navigation__toggle--active');
        headerLarge.removeAttribute('style');
      } else {
        headerLarge.style.paddingBottom = '60px';
        toggeleButton.classList.add('navigation__toggle--active');
      }
    });
    document.addEventListener('linkClicked', (event) => {
      if (toggeleButton.className.includes('navigation__toggle--active')) {
        setTimeout(() => {
          headerLarge.style.paddingBottom = event.detail.navRef
            ? event.detail.navRef.offsetHeight + 60 + 'px'
            : 60 + 'px';
        }, 0);
      }
    });
  },
  htmlContent: (data) => {
    return `<button class="navigation__toggle" type="button">
      <span class="navigation__toggle-icon">
          <span class="navigation__toggle-dot"></span>
          <span class="navigation__toggle-dot"></span>
          <span class="navigation__toggle-dot"></span>
          <span class="navigation__toggle-dot"></span>
          <span class="navigation__toggle-dot"></span>
          <span class="navigation__toggle-dot"></span>
          <span class="navigation__toggle-dot"></span>
          <span class="navigation__toggle-dot"></span>
          <span class="navigation__toggle-dot"></span>
      </span>
      Global Menu
    </button>
    ${startNavbarGlobal.htmlContent(data, 'toggle--link')}
    `;
  },
};
