export { searchInputNavBar };

const searchInputNavBar = {
  init: () => {
    const isRTL = localStorage.getItem('isRTL');
    var searhBarRef = document.querySelector('.search-bar'),
      searchParentElem = searhBarRef.parentElement,
      searchBarForm = searhBarRef.querySelector('.search-bar__form'),
      searchBarInput = searchBarForm.querySelector('.search-bar__input'),
      searchBarButton = searchBarForm.querySelector('.search-bar__button');
    if (window.outerWidth >= 1024) {
      searchBarForm
        .querySelector('.search-bar__show')
        .addEventListener('click', showSearch);
      searchBarForm
        .querySelector('.search-bar__hide')
        .addEventListener('click', hideSearch);
    } else {
      searchBarForm
        .querySelector('.search-bar__show')
        .addEventListener('click', () => {
          if (searchBarInput.value) searchRedirect();
        });
    }
    searchBarButton.addEventListener('click', () => {
      if (searchBarInput.value) searchRedirect();
    });
    function extractLangAndTerminal() {
      const url = window.location.href;

      const urlObj = new URL(url);

      const parts = urlObj.pathname.split('/');

      let lang = 'en';
      if (parts[1] !== undefined) {
        lang = parts[1];
      }

      let terminalName = '';
      if (parts[2] !== undefined) {
        terminalName = parts[2];
      }

      return { lang, terminalName };
    }

    function searchRedirect() {
      var langAndTerminal = extractLangAndTerminal();
      console.log(langAndTerminal);
      var url = `/en/Search-results?query=${searchBarInput.value}&lang=${langAndTerminal.lang}&terminal=${langAndTerminal.terminalName}`;
      window.location.href = encodeURI(url);
    }
    function u() {
      return window.innerWidth <= 1400 ? 30 : 0;
    }
    function o() {
      return isRTL
        ? window.outerWidth -
            searhBarRef.offsetLeft -
            searhBarRef
              .closest('.header__middle-bar, .header__main-bar')
              .querySelector('.header__logo').offsetWidth -
            u() -
            60
        : searhBarRef.offsetLeft -
            searhBarRef
              .closest('.header__middle-bar, .header__main-bar')
              .querySelector('.header__logo').offsetWidth -
            u() -
            40;
    }
    function s() {
      return isRTL
        ? searhBarRef.offsetLeft - 90 + u()
        : window.innerWidth - searhBarRef.offsetLeft - 124 + u();
    }
    function showSearch() {
      var n;
      searhBarRef.classList.contains('search-bar--open') ||
        ((n = s()),
        searchBarButton.offsetWidth > n
          ? isRTL
            ? (searchParentElem.style.paddingLeft =
                searchBarButton.offsetWidth - n + 'px')
            : (searchParentElem.style.paddingRight =
                searchBarButton.offsetWidth - n + 'px')
          : (searchBarButton.style.minWidth = n + 'px'),
        (searchBarForm.style.width = o() + 'px'),
        setTimeout(function () {
          searhBarRef.classList.add('search-bar--open');
          searchBarInput.focus();
        }, 200));
    }
    function hideSearch() {
      searchBarButton.style.minWidth = '';
      searchParentElem.style.paddingLeft = '';
      searchParentElem.style.paddingRight = '';
      searchBarForm.style.width = '';
      searhBarRef.classList.remove('search-bar--open');
    }
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        if (searchBarInput.value) searchRedirect();
      }
    });
  },
  htmlContent: () => {
    const lang = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : null;
    return `
        <div class="search-bar">
        <form class="search-bar__form">
            <button class="search-bar__show" type="button" aria-label="Open search bar">
                <span class="search-bar__icon">
                    <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" height="20" width="20">
                        <g fill="none" fill-rule="evenodd">
                            <path fill="#454545" fill-rule="nonzero"
                                d="M8.5 14a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zm6.372-1.542l5.835 5.835-1.414 1.414-5.7-5.7a7.5 7.5 0 1 1 1.28-1.549z">
                            </path>
                        </g>
                    </svg>
                </span>
            </button>
            <input aria-label=${lang.globalSearch} class="search-bar__input" id="searchQuery" name="SearchBarData.SearchQuery"
                placeholder=${lang.searchHere} type="text" value=""> <button type="button"
                class="close-button search-bar__hide" aria-label=${lang.closeSearchBar}></button>
            <div class="search-bar__button-wrap">
                <input class="button button--small search-bar__button" aria-label=${lang.search} value=${lang.search} style="">
            </div>
        </form>
    </div>
        `;
  },
};
