export { loginAndCreateNavBar };
import getfrConfig from '../../fr-config';
import sessionManager from '../../../../utils/sessionManager';
const profileUrl = getfrConfig().authority + '/user/settings/your-profile';

const loginAndCreateNavBar = {
  init: () => {
    let isMouseInsideParent = false;
    let leaveTimeout;
    const handleLoginClick = () => {
      initializeIGN();
      const userScopes = ['openid', 'profile', 'email'];
      window.IGN_IAM_Service_Provider.loginRedirect({
        response_type: 'code',
        scopes: userScopes,
      });
    };

    const handleLogout = (event) => {
      event.preventDefault();
      initializeIGN();
      window.IGN_IAM_Service_Provider.logoutRedirect();
    };

    if (document.getElementById('login')) {
      document
        .getElementById('login')
        .addEventListener('click', handleLoginClick);
    } else {
      const windowWidth = window.outerWidth;

      const logoutContainer = document.querySelector('.logout__container');
      const loginProfile__link = document.querySelector(
        '#loginProfile .navigation__link'
      );
      const loginProfile = document.getElementById('loginProfile');

      loginProfile__link.addEventListener(
        windowWidth > 1024 ? 'mouseenter' : 'click',
        () => {
          if (logoutContainer.classList.contains('animate_logout')) {
            removePopver(logoutContainer, true);
          } else {
            if (loginProfile.offsetWidth + 40 < 188) {
              logoutContainer.classList.add('move-left');
            } else {
              if (logoutContainer.classList.contains('move-left'))
                logoutContainer.classList.remove('move-left');
            }
            logoutContainer.style.display = 'block';
            isMouseInsideParent = true;
            setTimeout(() => {
              logoutContainer.classList.add('animate_logout');
            }, 100);
          }
        }
      );
      loginProfile__link.addEventListener('mouseleave', (e) => {
        e.preventDefault();
        if (!loginProfile__link.contains(e.relatedTarget)) {
          isMouseInsideParent = false;
        }
      });
      loginProfile.addEventListener('mouseenter', () => {
        clearTimeout(leaveTimeout);
      });
      loginProfile.addEventListener('mouseleave', () => {
        removePopver(logoutContainer);
      });
      document.getElementById('logout').addEventListener('click', handleLogout);
    }

    function removePopver(container, ignore) {
      clearTimeout(leaveTimeout);
      leaveTimeout = setTimeout(
        function () {
          if (!isMouseInsideParent || ignore) {
            container.classList.remove('animate_logout');
            container.removeAttribute('style');
          }
        },
        ignore ? 0 : 500
      );
    }

    function initializeIGN(){
      const isIgnLocalSet = JSON.parse(localStorage.getItem('IGN_Config'));
      window.IGN_IAM_Initialiser(isIgnLocalSet ? isIgnLocalSet : null);
    }
  },

  htmlContent: () => {
    const lang = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : null;
    const loggedInHtml = `
    <ul class="navigation__list navigation__list--logged-in">
        <li class="navigation__item" id="loginProfile">
          <a aria-label=${lang.myAccount} class="navigation__link" rel="nofollow" data-no-index="true">
              <div class="login-header">
                  <span class="login-header__icon">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" width="20" height="20"><path d="M10.125 8.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75zm2.5 2.813c1.726 0 3.125 1.482 3.125 3.31V17h-1.875v-2.253c0-.816-.585-1.435-1.25-1.435h-5c-.665 0-1.25.619-1.25 1.435V17H4.5v-2.253c0-1.828 1.4-3.31 3.125-3.31zM10.125 3a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5z"></path></svg>
                  </span>
              </div>
              <span class="login-header__profile" dir="ltr">NamePlaceholder</span>
          </a>
          <ul class="logout__container">
            <li class="logout__container__list__item">
              <a tabindex="-1" class="logout__container__list__link" href="${profileUrl}">
                ${lang.viewProfile}
              </a>
            </li>
            <li class="logout__container__list__item" id="logout">
              <a tabindex="-1" class="logout__container__list__link" rel="nofollow" data-no-index="true">
                ${lang.logout}
              </a>
            </li>
          </ul>
        </li>
    </ul>
`;
const loggedOutHtml = `
    <ul class="navigation__list navigation__list--logged-out">
        <li class="navigation__item">
            <a id="login" aria-label=${lang.login} class="navigation__link" rel="nofollow" data-no-index="true">
                <div class="login-header">
                    <span class="login-header__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" width="20" height="20"><path d="M10.125 8.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75zm2.5 2.813c1.726 0 3.125 1.482 3.125 3.31V17h-1.875v-2.253c0-.816-.585-1.435-1.25-1.435h-5c-.665 0-1.25.619-1.25 1.435V17H4.5v-2.253c0-1.828 1.4-3.31 3.125-3.31zM10.125 3a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5z"></path></svg>
                    </span>
                    <span class="login-header__text">${lang.login}</span>
                </div>
            </a>
        </li>
    </ul>
`;
    return tokenCheck()
      ? loggedInHtml.replace('NamePlaceholder', drawUserDetails())
      : loggedOutHtml;
  },

  refreshProfile: (token) => {
    const lang = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : null;
    const loggedInHtml = `
    <ul class="navigation__list navigation__list--logged-in">
        <li class="navigation__item" id="loginProfile">
          <a aria-label=${lang.myAccount} class="navigation__link" rel="nofollow" data-no-index="true">
              <div class="login-header">
                  <span class="login-header__icon">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" width="20" height="20"><path d="M10.125 8.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75zm2.5 2.813c1.726 0 3.125 1.482 3.125 3.31V17h-1.875v-2.253c0-.816-.585-1.435-1.25-1.435h-5c-.665 0-1.25.619-1.25 1.435V17H4.5v-2.253c0-1.828 1.4-3.31 3.125-3.31zM10.125 3a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5z"></path></svg>
                  </span>
              </div>
              <span class="login-header__profile" dir="ltr">NamePlaceholder</span>
          </a>
          <ul class="logout__container">
            <li class="logout__container__list__item">
              <a tabindex="-1" class="logout__container__list__link" href="${profileUrl}">
                ${lang.viewProfile}
              </a>
            </li>
            <li class="logout__container__list__item" id="logout">
              <a tabindex="-1" class="logout__container__list__link" rel="nofollow" data-no-index="true">
                ${lang.logout}
              </a>
            </li>
          </ul>
        </li>
    </ul>
`;
    if (token) {
      tokenCheck();
      if (document.querySelector('.navigation__list--logged-out')) {
        document.querySelector('.navigation__list--logged-out').remove();
      }
      document.querySelector('.navigation--login').innerHTML =
        loggedInHtml.replace('NamePlaceholder', drawUserDetails());
      loginAndCreateNavBar.init();
    }
  },
};

const drawUserDetails = () => {
  if (window.innerWidth > 1024) {
    const config = getfrConfig();
    let jwt = localStorage.getItem(
      `al.${config.authority}.${config.client_id}.id_token`
    );
    const base64 = jwt.split('.')[1].replace('-', '+').replace('_', '/');
    let toReturn = JSON.parse(atob(base64));
    toReturn = `${toReturn.firstname} ${toReturn.lastname}`;
    toReturn =
      toReturn.length > 23 ? toReturn.substring(0, 23 - 3) + '...' : toReturn;
    return `Welcome ${toReturn}`;
  } else {
    return '';
  }
};

const tokenCheck = () => {
  const config = getfrConfig();
  const sessionCheck = new sessionManager();

  const accessToken = localStorage.getItem(
    `al.${config.authority}.${config.client_id}.access_token`
  );
  const timestamp = localStorage.getItem(
    `al.${config.authority}.${config.client_id}.expiry_date`
  );

  if (accessToken && timestamp) {
    let isTokenValid = sessionCheck.isTokenValid(timestamp);

    if (accessToken && isTokenValid && accessToken !== 'undefined') {
      drawUserDetails(accessToken);
    }

    if (!isTokenValid) {
      sessionCheck.refreshToken();
    }
    return accessToken && isTokenValid;
  } else {
    return false;
  }
};
