export { addCookiePolicy };

const addCookiePolicy = () => {
    let documentElement = document.createElement('script');
    documentElement.setAttribute(
      'src',
      'https://policy.app.cookieinformation.com/uc.js'
    );
    documentElement.setAttribute('data-culture', 'EN');
    documentElement.setAttribute('type', 'text/javascript');
    documentElement.setAttribute('id', 'CookieConsent');
  
    document.head.appendChild(documentElement);
  };
