import { subMenu } from './nav-sub-menu';
export { startNavbarGlobal };

const generateLinks = (data) => {
  var html = [];
  data.forEach((x) => {
    html.push(`<li class="navigation__item">
                <button type="button" class="navigation__link" tabindex="0" aria-haspopup="true" aria-expanded="false">
                  ${x.MenuTitle}
                  <span class="navigation__arrow-icon"></span>
                </button>
                <div class="secondary-navigation  secondary-navigation--with-border">${subMenu(
                  x.ChildItems
                )}</div>
              <li>`);
  });
  return html.join('');
};

const removeAllActiveClassFromList = (navList, selectedNav) => {
  navList.forEach((e) => {
    const navRef = selectedNav ? selectedNav : e;
    if (e.className.includes('navigation__link--active')) {
      const secondaryNavRef =
        e.parentElement.children[navRef.parentElement.children.length - 1];
      addTranslateVisibleSecondary(secondaryNavRef, 'remove');
      e.classList.remove('navigation__link--active');
      setTimeout(() => {
        secondaryNavRef.classList.remove('secondary-navigation--active');
      }, 300);
      e.parentNode.classList.remove('navigation__item--active');
      addAniMobileFormSecondaryNav(e, 'remove');
      navRef.setAttribute('aria-expanded', false);
    }
  });
  const customEvent = new CustomEvent('linkClicked', {
    detail: { navRef: null },
  });
  document.dispatchEvent(customEvent);
};

const handleNavClick = (navList, selectedNav, showNav = true) => {
  removeAllActiveClassFromList(navList, selectedNav);
  if (showNav) {
    selectedNav.classList.add('navigation__link--active');
    selectedNav.setAttribute('aria-haspopup', true);
    selectedNav.setAttribute('aria-expanded', true);

    const secondaryNavRef =
      selectedNav.parentElement.children[
        selectedNav.parentElement.children.length - 1
      ];
    secondaryNavRef.classList.add('secondary-navigation--active');
    const customEvent = new CustomEvent('linkClicked', {
      detail: { navRef: secondaryNavRef },
    });
    document.dispatchEvent(customEvent);
    setTimeout(() => {
      addTranslateVisibleSecondary(secondaryNavRef, 'add');
    }, 300);

    selectedNav.parentNode.classList.add('navigation__item--active');
    addAniMobileFormSecondaryNav(selectedNav, 'add');
  }
};

const addAniMobileFormSecondaryNav = (navref, type) => {
  let secondaryNavRef =
    navref.parentElement.children[navref.parentElement.children.length - 1];
  if (type === 'add') {
    secondaryNavRef.style.display = 'block';

    setTimeout(() => {
      secondaryNavRef.firstChild.style.opacity = 1;
      secondaryNavRef.firstChild.style.transform = 'translateY(0px)';
      secondaryNavRef.firstChild.style.transitionDuration = '0.3s';
    }, 100);
  } else {
    setTimeout(() => {
      secondaryNavRef.removeAttribute('style');
    }, 300);

    secondaryNavRef.firstChild.removeAttribute('style');
  }
};

const addTranslateVisibleSecondary = (navref, type) => {
  const secondaryNavItems = navref.querySelectorAll(
    '.secondary-navigation__item'
  );
  secondaryNavItems.forEach((element) => {
    if (type === 'add') {
      element.classList.add('secondary-navigation__item--visible');
    } else {
      element.classList.remove('secondary-navigation__item--visible');
    }
  });
};

const menuClickBindEvent = () => {
  let menuIcon = document.querySelector('.menu-icon');
  if (menuIcon) {
    menuIcon.addEventListener('click', () => {
      let headerRef = document.querySelector('.header');
      if (headerRef.className.includes('header--navigation-open')) {
        headerRef.classList.remove(
          'header--navigation-open',
          'header--fade-in-navigation'
        );
      } else {
        headerRef.classList.add('header--navigation-open');
        setTimeout(() => {
          headerRef.classList.add('header--fade-in-navigation');
        }, 0);
        if (localStorage.getItem('terminalNav') === true) {
          setOtherMobileItems();
        }
      }
    });
  }
};

const setOtherMobileItems = () => {
  document.querySelector(
    '.header__main-bar--terminal .header__group'
  ).style.display = 'flex';
};

const startNavbarGlobal = {
  init: () => {
    let navList = document.querySelectorAll(
      '.navigation--global .navigation__link'
    );
    if (navList) {
      navList.forEach((el, index) => {
        el.addEventListener('click', (e) => {
          if (e.detail < 2) {
            e.preventDefault();
            e.stopPropagation();
            handleNavClick(
              navList,
              el,
              !el.className.includes('navigation__link--active')
            );
            el.setAttribute('tabIndex', index);
          }
        });
      });
    }
    menuClickBindEvent();
    document.addEventListener('click', function (event) {
      const navigation = document.querySelector('.secondary-navigation');
      const navLinks = document.querySelectorAll('.navigation__link');
      var listItemsArray = Array.from(navLinks);
      if (
        !navigation.contains(event.target) &&
        !listItemsArray.includes(event.target)
      ) {
        removeAllActiveClassFromList(navList);
      }
    });
  },
  htmlContent: (data, classType) => {
    if (classType) {
      return `<ul class="navigation__list">
      ${generateLinks(data)}
    </ul>`;
    } else {
      return `<nav class="navigation navigation--global">
    <ul class="navigation__list">
      ${generateLinks(data)}
    </ul>
  </nav>`;
    }
  },
};
