import { getUrlSegmentForLang, checkForThirdSegment, getSegmentsFollowingterminal } from './../../../../utils/helpers';

export { terminalNavLanguageSelector };

const terminalNavLanguageSelector = {
  init: () => {
    let querySelector = document.querySelector('.navigation--language');
    if (querySelector && window.outerWidth > 1024) {
      querySelector.addEventListener('mouseover', (e) => {
        e.preventDefault();
        document
          .querySelector('.terminal-secondary-nav')
          .classList.add('secondary-navigation--active');
      });
    }
    let prevSecondaryNav;
    let isMouseInsideParent = false;
    let leaveTimeout;
    let navButtonLink = document.querySelector('.lang_btn_link');
    if (window.outerWidth > 1024) {
      let topMenuList = document.querySelector('.lang-nav-item');
      if (navButtonLink) {
        navButtonLink.addEventListener('mouseenter', (e) => {
          e.preventDefault();
          isMouseInsideParent = true;
          navButtonLink.nextElementSibling.classList.remove(
            'secondary-navigation--active'
          );
          e.target.classList.add('navigation__link--active');
          e.target.parentElement.lastElementChild.classList.add(
            'secondary-navigation--active'
          );
          prevSecondaryNav = e.target.parentElement.lastElementChild;
        });
        navButtonLink.addEventListener('mouseleave', (e) => {
          e.preventDefault();
          e.target.classList.remove('navigation__link--active');
          if (!navButtonLink.contains(e.relatedTarget)) {
            isMouseInsideParent = false;
          }
        });
      }

      if (topMenuList) {
        topMenuList.addEventListener('mouseleave', () => {
          clearTimeout(leaveTimeout);
          leaveTimeout = setTimeout(function () {
            if (!isMouseInsideParent) {
              if (prevSecondaryNav.classList) {
                prevSecondaryNav.classList.remove('secondary-navigation--active');
              }
            }
          }, 500);
        });
      }

      const navLinks = document.querySelectorAll('.terminal-secondary-nav');
      let secondaryLeave;
      var listItemsArray = Array.from(navLinks);
      for (let i = 0; i < listItemsArray.length; i++) {
        listItemsArray[i].addEventListener('mouseenter', function () {
          isMouseInsideParent = true;
        });
        listItemsArray[i].addEventListener('mouseleave', (e) => {
          clearTimeout(secondaryLeave);
          secondaryLeave = setTimeout(function () {
            if (!listItemsArray[i].contains(e.relatedTarget)) {
              isMouseInsideParent = false;
            }
          }, 500);
        });
      }
    } else if(navButtonLink) {
      navButtonLink.addEventListener('click', (el) => {
        const secondaryNav = navButtonLink.nextElementSibling;
        if (el.target.className.includes('navigation__link--active')) {
          el.target.classList.remove('navigation__link--active');
          querySelector.classList.remove('navigation--active');
          setTimeout(() => {
            secondaryNav.removeAttribute('style');
          }, 100);
          secondaryNav.querySelector(':first-child').removeAttribute('style');
        } else {
          secondaryNav.style.display = 'block';
          setTimeout(() => {
            secondaryNav.querySelector(':first-child').style.display = 'block';
            secondaryNav.querySelector(':first-child').style.opacity = 1;
            secondaryNav.querySelector(':first-child').style.transform =
              'translateY(0px)';
          }, 100);
          secondaryNav.style.transitionDuration = '0.3s';
          el.target.classList.add('navigation__link--active');
          querySelector.classList.add('navigation--active');
        }
      });
    }
  },
  htmlContent: (menuItems) => {
    const lang = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : null;
    if (menuItems && menuItems.length > 1) {
      return `<div class="header__separator"></div>
              <nav class="navigation navigation--dropdown navigation--language">
                  <ul class="navigation__list">
                      <li class="lang-nav-item navigation__item">
                          <button type="button" class="lang_btn_link navigation__link" tabindex="0" aria-haspopup="true" aria-expanded="false">
                              <div class="language-selector">
                                  <span class="language-selector__icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 18 18" height="18" width="18">
                                          <path transform="translate(-1 -1)" fill="#ff6441" d="M10 1a9 9 0 110 18 9 9 0 010-18zm1.979 10H8.022c.083 1.926.396 3.866.941 5.822l-.041-.158a6.799 6.799 0 002.173-.002l-.055-.018a23.82 23.82 0 00.939-5.643zm4.697 0H13.98a25.557 25.557 0 01-.672 4.886A6.754 6.754 0 0016.676 11zM6.02 11H3.324a6.755 6.755 0 003.34 4.87A27.117 27.117 0 016.02 11zm.65-6.873l-.14.082a6.754 6.754 0 00-3.207 4.792h2.695c.061-1.638.279-3.263.652-4.874zm6.652-.004l.094.423c.317 1.491.505 2.976.564 4.455h2.697a6.755 6.755 0 00-3.355-4.878zM10 3.25a6.8 6.8 0 00-1.058.082A23.983 23.983 0 008.02 9h3.958a25.028 25.028 0 00-.94-5.742l.02.074A6.8 6.8 0 0010 3.25z"></path>
                                      </svg>
                                  </span>
                                      <span class="language-selector__text">${getUrlLanguage(
                                        menuItems
                                      )}</span>

                              </div>
                              <span class="navigation__arrow-icon"></span>
                          </button>
                          <div class="terminal-secondary-nav secondary-navigation secondary-navigation--reverse" aria-hidden="true">
                              <ul class="secondary-navigation__list">
                                  ${languageSelector(menuItems)}
                              </ul>
                              <button type="button" class="close-button" aria-label=${lang.closeLanguageSelector}></button>
                          </div>
                      </li>
                  </ul>
              </nav>`;
    }
    return '';
  },
};

const getUrlLanguage = (data) => {
  // Check if the URL contains the language code with arabic
  const isArabic = getUrlSegmentForLang(window.location.href) === 'ar' ? true : false;
  if (isArabic) {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    localStorage.setItem('isRTL', JSON.parse(true));
  } else {
    localStorage.removeItem('isRTL');
  }

  if(window.location.href.indexOf('isoCode=') < 0) {
    let currentLanguage = data.filter((el) => el.LanguageCode === window.location.pathname.split('/').filter(Boolean)[0]);
    return currentLanguage[0].LanguageName;
  }
  else if (data.length > 0) {
    let language = window.location.href.split('isoCode=')[1];
    let selectedMenuLanguage = data.filter(
      (el) =>
     el.PageLanguageUrl.split('isoCode=')[1] == language)[0];
    let selectedLanguage = window.location.pathname.includes('isoCode=en')
      ? 'English'
      : selectedMenuLanguage.LanguageName;    
    return selectedLanguage;
  }
};

const languageSelector = (data) => {
  let htmlContent = '';
  if (data && data.length > 0) {
    let currentIsoCode = data[0].PageLanguageUrl.split('/')[1];
    data.forEach((el) => {
      let selectedLanguage = el.LanguageName;
      let hrefUrl;
      if (!window.location.pathname.includes(selectedLanguage)) {
        let newIsoCode = el.PageLanguageUrl.split('isoCode=')[1];
        hrefUrl = el.PageLanguageUrl.replace(currentIsoCode, newIsoCode);
        if(checkForThirdSegment){
            const domainSting = window.location.href.split('/').slice(0, 3).join('/');
            const langTerminal = hrefUrl.split('?isoCode=')[0];
            const segments = getSegmentsFollowingterminal(window.location.href);
            const newURL = `${domainSting}${langTerminal}/${segments.join('/')}`;
            hrefUrl = `${newURL}?isoCode=${newIsoCode}`;
        }
        htmlContent += `<li class="secondary-navigation__item">
                          <a class="secondary-navigation__link" href="${hrefUrl}">${selectedLanguage}</a>
                        </li>`;
      }
    });
  }
  return htmlContent;
};
