export { terminalSwipe };

const terminalSwipe = {
  init: () => {
    const swipeRightElement = document.querySelector(
      '.navigation__text-helper--right'
    );
    const swipeLeftElement = document.querySelector(
      '.navigation__text-helper--left'
    );
    const logoTextElement = document.querySelectorAll('.header__logo-link');
    swipeRightElement.style.transform = 'translateX(100px)';
    swipeRightElement.style.opacity = 0;

    let startX, startY, endX, endY;
    const minSwipeDistance = 50;
    const targetElement = document.querySelectorAll('.navigation__text-helper');

    targetElement.forEach((element) => {
      element.addEventListener('touchstart', touchStart);
      element.addEventListener('touchend', touchEnd);
    });
    function touchStart(event) {
      startX = event.touches[0].clientX;
      startY = event.touches[0].clientY;
    }

    function touchEnd(event) {
      endX = event.changedTouches[0].clientX;
      endY = event.changedTouches[0].clientY;
      const distanceX = endX - startX;
      const distanceY = endY - startY;
      if (
        Math.abs(distanceX) > minSwipeDistance ||
        Math.abs(distanceY) > minSwipeDistance
      ) {
        if (Math.abs(distanceX) > Math.abs(distanceY)) {
          if (distanceX > 0) {
            /* This is for Right Swipe text animation */
            swipeRightElement.style.opacity = 0;
            swipeRightElement.style.transform = 'translateX(-100px)';
            swipeLeftElement.style.opacity = 1;
            swipeLeftElement.style.transform = 'translateX(0px)';
            /* This is for Right Swipe text animation */
            /* This is for Right Swipe logo animation */
            logoTextElement[0].style.opacity = 1;
            logoTextElement[0].style.transform = 'translateX(0px)';
            logoTextElement[1].style.opacity = 0;
            logoTextElement[1].style.transform = 'translateX(100px)';
            document
              .querySelector('.header--navigation-open')
              .classList.remove('header--show-navigation-global');
            /* This is for Right Swipe logo animation */
          } else {
            /* This is for Left Swipe text animation */
            document
              .querySelector('.header--navigation-open')
              .classList.add('header--show-navigation-global');
            swipeLeftElement.style.opacity = 0;
            swipeLeftElement.style.transform = 'translateX(-100px)';
            swipeRightElement.style.opacity = 1;
            swipeRightElement.style.transform = 'translateX(0px)';
            /* This is for Left Swipe text animation */
            /* This is for Left Swipe logo animation */
            logoTextElement[0].style.opacity = 0;
            logoTextElement[0].style.transform = 'translateX(-100px)';
            logoTextElement[1].style.opacity = 1;
            logoTextElement[1].style.transform = 'translateX(0px)';
            /* This is for Left Swipe logo animation */
          }
        }
      }
    }
  },
  htmlContent: () => {
    const lang = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : null;
    return `
        <span class="navigation__text-helper navigation__text-helper--right bold" style="transform: translateX(100px);">
        ${lang.swipeRightForTerminalNavigation}
        <span class="navigation__icon-helper">
            <svg width="16" height="14">
                <g fill="none" fill-rule="evenodd" transform="rotate(0 8 7)">
                    <path fill="#ff6441" d="M11.879 5.593l-4.18-4.179L9.115 0l6.6 6.6-6.707 6.707-1.414-1.414 4.3-4.3H0v-2h11.879z"></path>
                </g>
            </svg>
        </span>
        </span>
        <span class="navigation__text-helper navigation__text-helper--left bold">
        <span class="navigation__icon-helper">
            <svg width="16" height="14">
                <g fill="none" fill-rule="evenodd" transform="rotate(180 8 7)">
                    <path fill="#ff6441" d="M11.879 5.593l-4.18-4.179L9.115 0l6.6 6.6-6.707 6.707-1.414-1.414 4.3-4.3H0v-2h11.879z"></path>
                </g>
            </svg>
        </span>
        ${lang.swipeLeftForGlobalNavigation}
        </span>`;
  },
};
