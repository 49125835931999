const getUrlSegmentForLang = (url) => {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/');
    const filteredSegments = pathSegments.filter(segment => segment.length > 0);
    return filteredSegments[0] || 'en';
};

const checkForThirdSegment = (url) => {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/');
    const filteredSegments = pathSegments.filter(segment => segment.length > 0);
    return filteredSegments.length > 2 ? true : false;
};

const getSegmentsFollowingterminal = (url) => {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/');
    const filteredSegments = pathSegments.filter(segment => segment.length > 0);
    return filteredSegments.slice(2);
};

export { getUrlSegmentForLang, checkForThirdSegment, getSegmentsFollowingterminal } ;
