import { socialIcon } from '../components/global-nav/social-icons';

export { startFooter };

const startFooter = async (footerContent) => {
  let htmlContent = '';
  if (footerContent) {
    htmlContent = `<div class="footer__top">
          <div class="footer__top-inner wrapper">
            <div class="footer__logo">
                <img src="https://www.apmterminals.com/-/media/sitewide/apmt-footer-logo.svg" alt="apmt-footer-logo" width="294" height="70" title="apmt-footer-logo">
            </div>
            ${socialIcon(footerContent.SocialLinks)}
          </div>
          <div class="footer__top-inner wrapper">${getFooterIntro(
            footerContent
          )}${getFooterLinks(footerContent.QuickLinks)}</div>
          </div>
          <div class="footer-terms">${getFooterTerms(footerContent.AdditionalLinks)}</div>`;
  }
  let documentElement = document.createElement('footer');
  documentElement.id = 'ign_footer';
  documentElement.className = 'footer';
  document.body.appendChild(documentElement);
  let ign_footer = document.getElementById('ign_footer');
  ign_footer.innerHTML = htmlContent;
};

const getFooterIntro = (data) => {
  let content = `<div class="footer__card">
                <h2 class="h4">${data.CardTitle}</h2>
                <p class="footer__card--description">${data.CardDescription}</p>
                <div class="footer__quick-link"><a href="${data.CardCta.PageUrl}" >${data.CardCta.MenuTitle}</a></div>
            </div>`;
  return content;
};

const getFooterLinks = (data) => {
  let content = '';
  let links = '';

  data.ChildItems.forEach((link) => {
    links = `${links}<li class="footer__quick-link"><a href="${link.PageUrl}" >${link.MenuTitle}</a></li>`;
  });

  content = `<div class="footer__quick-links">
                <h2 class="h4">${data.Title}</h2>
                <div class="footer-container_links-anchor"><ul>${links}</ul></div>
            </div>`;
  return content;
};

const getFooterTerms = (data) => {
  let content = '';
  let links = '';

  data.ChildItems.forEach((link) => {
    links = `${links}<a href="${link.PageUrl}" >${link.MenuTitle}</a>`;
  });

  content = `<div class="footer-terms__bottom-inner wrapper">
              <p class="footer-terms_title">${data.Title}</p>
              <div class="footer-terms_links">${links}</div>
            </div>`;
  return content;
};
