import axios from 'axios';

function setCache(key, data) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

function getCache(key) {
  const cachedData = sessionStorage.getItem(key);
  if (cachedData) {
    return JSON.parse(cachedData);
  }
  return null;
}

async function fetchDataFromApi(endpoint) {
  try {
    const headers = {
      'Consumer-Key': process.env.API_CONSUMER_KEY,
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      mode: 'cors',
    };
    if (process.env.API_BYPASS_KEY) {
      headers['x-apmtdev'] = process.env.API_BYPASS_KEY;
    }

    const response = await axios.get(`${endpoint}`, { headers });
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
}

const RestApi = class {
  async resolveContent(terminalPath) {
    this.apiContent = await this.getApiContent(terminalPath);
    this.header = this.apiContent.HeaderItems;
    this.footer = this.apiContent.Footer;
    this.terminals = this.apiContent.Terminals;
    this.globalAlert = this.apiContent.GlobalAlert;

    this.isTerminalNav =
      this.apiContent.TerminalNavigation &&
      this.apiContent.TerminalNavigation.length > 0;
    this.terminalNav = this.apiContent.TerminalNavigation;
    this.terminalLanguages = this.apiContent.TerminalLanguages;
    this.hasTerminalAlert =
      this.apiContent.TerminalAlert && this.apiContent.TerminalAlert.length > 0;
    this.terminalAlert = this.apiContent.TerminalAlert;
    this.terminalHeaderTitle = this.apiContent.TerminalHeaderTitle;
  }

  async getApiContent(terminalPath = '') {
    const cacheKey = `ign-nav-1.0 - ${terminalPath}`;
    const cachedData = getCache(cacheKey);

    if (cachedData) {
      return cachedData;
    }

    var versionPath = process.env.APP_BASE_VERSION;
    var apiPath = terminalPath;

    if (apiPath.includes(versionPath)) {
      apiPath = apiPath.replace(`/${versionPath}`, '');
    }

    var queryParam = terminalPath === '/' ? '' : `?terminalPagePath=${apiPath}`;

    let apiResponse = await fetchDataFromApi(
      `${process.env.API_BASE_URL}/globalnav${queryParam}`
    );

    setCache(cacheKey, apiResponse);

    return apiResponse;
  }
};

export default RestApi;
