import { terminalNavMenuItems } from './terminal-nav-menu-items';
import { searchInputNavBar } from '../global-nav/header-input-search';
import { terminalNavLanguageSelector } from './terminal-nav-language-selector';
import { getTerminalRegion } from './terminal-region';
export { terminalSubHeader };

const terminalSubHeader = {
  init: () => {
    bindEvents();
  },
  htmlContent: (menuItems, languageItems, terminalHeaderTitle) => {
    const terminalRegion = getTerminalRegion();
    return `<div class="header__main-bar header__main-bar--terminal">
        <div class="header__group" style="width: 100%">
            <div class="header__logo header__logo--terminal">
                <a class="header__logo-link header__logo-link--terminal" href="${
                  terminalRegion.url
                }">
                    ${terminalHeaderTitle}
                </a>
                <a class="header__logo-link header__logo-link--apm header__logo-link--terminal" href="/">
                    APM Terminals
                </a>
            </div>
            ${terminalNavMenuItems.htmlContent(menuItems)}
            ${searchInputNavBar.htmlContent()}
            ${terminalNavLanguageSelector.htmlContent(languageItems)}
        </div>
    </div>`;
  },
};


const bindEvents = () => {
  terminalNavLanguageSelector.init();
};
