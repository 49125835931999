import { loginAndCreateNavBar } from './components/global-nav/header-login-create';

const getfrConfig = (config) => {
  return {
    /**
     * @description: the uri that's whitelisted in AM for OAuth2
     */
    redirect_uri: config ? config.appURL : process.env.APP_BASE_URL,

    /**
     * @description: Your application client id
     */
    client_id: config ? config.clientId : 'apmt001',

    /**
     * @description: The url of the authority, e.g https://accounts.maersk.com/developer
     */
    authority: config ? config.authority : process.env.APP_AUTHORITY,
    storage: {
      /**
       * @default: "localStorage"
       * @description: the type of storage you want to utilize to store the tokens
       * choices are sessionStoragesession lived tokens, and localStorage, for permanent storing (until deleted)
       */
      type: 'localStorage',
    },

    /**
     * default: "iamAL"
     * this will give the AL instance name in the global scope (window.iamAL)
     */
    window_namespace: 'iamAL',

    /**
     * Get optional post_logout_uri property, falling back to appUrl property then back to app's running URL.
     */
    post_logout_uri: config ? config.post_logout_uri : undefined,

    login_redirect_cb: (data, error) => {
      if (data) {
        var urlWithoutParams =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname;
        var newURL = urlWithoutParams;
        localStorage.setItem('IGN_success', true);

        const IGN_event = new Event('IGN_Loaded');
        const targetElement = document.body;
        targetElement.dispatchEvent(IGN_event);

        window.history.pushState({}, '', newURL);
        loginAndCreateNavBar.refreshProfile(data);
      } else if (error) {
        console.error('login_redirect_cb called with error', error);
        localStorage.setItem('IGN_success', false);

        const IGN_event = new Event('IGN_Failed');
        const targetElement = document.body;
        targetElement.dispatchEvent(IGN_event);
      }
    },
  };
};

export default getfrConfig;
