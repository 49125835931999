import { startHeader } from './v1/js/header/header';
import { startHeaderLoader } from './v1/js/header/header-loader';
import { startFooter } from './v1/js/footer/footer';
import { startTerminalNav } from './v1/js/components/terminal-nav/terminal-nav-header';
import RestApi from './utils/rest-apis';
import { globalAlert } from './v1/js/components/global-alert';
import getfrConfig from './v1/js/fr-config';
import { UserAgentPublicApp } from '@maersk/iam-al';
import sessionManager from './utils/sessionManager';
import { getUrlSegmentForLang } from './utils/helpers';
import LangJson from './locale/languages.json';

const loadGlobalNav = (apiContent) => {
  localStorage.setItem('terminalNav', false);
  const hasRTL = localStorage.getItem('isRTL');
  if (hasRTL) {
    localStorage.removeItem('isRTL');
  }
  startHeader(apiContent);
  startFooter(apiContent.footer);
};

const loadTerminalNav = (apiContent) => {
  localStorage.setItem('terminalNav', true);
  var path = window.location.pathname;
  if (path.endsWith('/')) {
    path = path.slice(0, -1);
  }

  localStorage.setItem(
    'terminal-region',
    JSON.stringify({
      name: path.split('/').pop(),
      url: path,
    })
  );
  startTerminalNav(apiContent);
  startFooter(apiContent.footer);
};

const loadGlobalAlert = (globalAlertContent) => {
  localStorage.removeItem('terminal-region');
  if (sessionStorage.getItem('global-alert') !== 'hide') {
    let documentElement = document.createElement('div');
    documentElement.className = 'crisis-alert modal';
    document.body.appendChild(documentElement);
    let alertModal = document.getElementsByClassName('crisis-alert')[0];
    alertModal.innerHTML = globalAlert.htmlContent(globalAlertContent);
    globalAlert.init();
  }
};

const loadIam = async () => {
  await checkNavCrossedOneHour();
  window.IGN_IAM_Initialiser = (config) => {
    if (config) {
      localStorage.setItem('IGN_Config', JSON.stringify(config));
      console.log(`IGN config from product app = ${JSON.stringify(config)}`);
    }
    const frConfig = getfrConfig(config);
    console.debug('Forgerock Config:', frConfig);
    window.IGN_IAM_Service_Provider = new UserAgentPublicApp(frConfig);
  };
  if (
    window.location.href.includes('code') &&
    window.location.href.includes('client_id')
  ) {
    window.IGN_IAM_Initialiser(
      localStorage.getItem('IGN_Config')
        ? JSON.parse(localStorage.getItem('IGN_Config'))
        : null
    );
  }
};

async function checkNavCrossedOneHour() {
  const previousTime = localStorage.getItem('ign-nav-timestamp');
  const currentTime = new Date().getTime();
  if (previousTime) {
    const timeDifference = currentTime - previousTime;
    if (timeDifference >= process.env.MAX_EXPIRY_NAV_TIME) {
      sessionStorage.removeItem('ign-nav-1.0 - globalnav');
      sessionStorage.removeItem('ign-nav-1.0 - terminalnav');
      localStorage.setItem('ign-nav-timestamp', new Date().getTime());
    }
  } else {
    localStorage.setItem('ign-nav-timestamp', new Date().getTime());
  }
}

window.addEventListener('load', async () => {
  const checkIgnInitialized = localStorage.getItem('IGN_Initialized');
  if (checkIgnInitialized) localStorage.removeItem('IGN_Initialized');
  const t0 = performance.now();
  await loadIam();
  let link = document.createElement('link');
  link.rel = 'icon';
  document.head.appendChild(link);
  link.href = process.env.FAVICON_URL;
  startHeaderLoader();
  const apiContent = new RestApi();
  await apiContent.resolveContent(window.location.pathname);
  let headerContent = apiContent.header;

  const t1 = performance.now();
  const lang = getUrlSegmentForLang(window.location.href);
  const currentLang = LangJson[lang] || LangJson.en;
  localStorage.setItem('lang', JSON.stringify(currentLang));
  if (headerContent) {
    apiContent.isTerminalNav
      ? loadTerminalNav(apiContent)
      : loadGlobalNav(apiContent);
    loadGlobalAlert(apiContent.globalAlert);
    document.getElementById('ign_header__loader').remove();
  }
  const total = performance.now();
  console.log(`Load time of IGN ${parseFloat(total - t1).toFixed(2)} ms.`);
  console.log(
    `Load time of IGN - with API ${parseFloat(total - t0).toFixed(2)} ms.`
  );

  // code to expose refresh token
  const sessionCheck = new sessionManager();
  window.IGN_Refresh_Token = sessionCheck.refreshToken;

  const IGN_event = new Event('IGN_Init');
  const targetElement = document;
  targetElement.dispatchEvent(IGN_event);
  localStorage.setItem('IGN_Initialized', JSON.parse('true'));
});
