import getfrConfig from '../v1/js/fr-config';

const sessionManager = class {
  timestampConvertor(timestamp) {
    let formattedDate = new Date();
    if (timestamp) {
      const date = new Date(timestamp);
      // Extract the different components of the date
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Month is zero-based, so we add 1
      const day = date.getDate();

      formattedDate = `${day}/${month}/${year}`;
    }

    return formattedDate;
  }

  isTokenValid(timestamp) {
    let currentDate = new Date().getTime();
    return currentDate < timestamp;
  }

  refreshToken() {
    if ('IGN_IAM_Service_Provider' in window) {
      const config = getfrConfig();
      let tokenHeaders = new Headers();
      tokenHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

      let urlencoded = new URLSearchParams();
      urlencoded.append('decision', 'allow');
      urlencoded.append('client_id', 'apmt001');
      urlencoded.append('carrier', 'APMT');
      urlencoded.append('grant_type', 'refresh_token');
      urlencoded.append(
        'refresh_token',
        localStorage.getItem(
          `al.${config.authority}.${config.client_id}.refresh_token`
        )
      );

      let requestOptions = {
        method: 'POST',
        headers: tokenHeaders,
        body: urlencoded,
        redirect: 'follow',
      };

      fetch(
        `${config.authority}/acm/oauth2/realms/apmt/access_token`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result) {
            let resultdata = JSON.parse(result);
            localStorage.setItem(
              `al.${config.authority}.${config.client_id}.access_token`,
              resultdata.access_token
            );
            localStorage.setItem(
              `al.${config.authority}.${config.client_id}.refresh_token`,
              resultdata.refresh_token
            );
            localStorage.setItem(
              `al.${config.authority}.${config.client_id}.id_token`,
              resultdata.id_token
            );
            localStorage.setItem(
              `al.${config.authority}.${config.client_id}.expiry_date`,
              (
                new Date().getTime() +
                parseInt(resultdata.expires_in) * 1000
              ).toString()
            );
          }
        })
        .catch((error) => console.log('error', error));
    }
  }
};

export default sessionManager;
