export { subMenu };

const subMenu = (data) => {
  let links = '';
  if (data) {
    data.forEach((link, index) => {
      let url = `${link.PageUrl}`;
      links = `${links}<li class="secondary-navigation__item">
                            <a tabindex="${index}" class="secondary-navigation__link" href=${url}>
                                ${link.MenuTitle}
                            </a>
                        </li>`;
    });
  }

  return `<ul class="secondary-navigation__list">${links}</ul>`;
};
